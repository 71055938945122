import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'
import auth from '../middlewares/auth'
import passwordUpdate from '../middlewares/passwordUpdate'
import access from '../middlewares/access'
const appName = process.env.VUE_APP_NAME.split('.')[0]
Vue.use(VueRouter)
const routes = [
    {
        name: 'Layout',
        path: '/',
        component: Layout,
        redirect: 'home',
        children: [
            {
                meta: {
                    title: 'menu.dashboard',
                    middleware: [auth, passwordUpdate]
                },
                path: '/home',
                name: 'home',
                component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
            },
            {
                meta: {
                    title: 'def.profile',
                    middleware: [auth, access]
                },
                path: '/profile',
                name: 'profile',
                component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
            },
            {
                meta: {
                    title: 'menu.manualTransaction',
                    middleware: [auth, access, passwordUpdate]
                },
                path: '/manual-transaction',
                name: 'manualTransaction',
                component: () => import(/* webpackChunkName: "profile" */ '../views/ManualTransaction/List.vue')
            },
            {
                meta: {
                    title: 'menu.cageTurnover',
                    middleware: [auth, access, passwordUpdate]
                },
                path: '/cage-turnovers',
                name: 'cageTurnovers',
                component: () => import(/* webpackChunkName: "profile" */ '../views/CageTurnover/List.vue')
            },
            {
                meta: {
                    title: 'menu.deposits',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'Menu.Deposit'
                },
                path: '/deposit',
                name: 'deposit',
                component: () => import(/* webpackChunkName: "transaction" */ '../views/Deposit/List.vue')
            },
            {
                meta: {
                    title: 'def.bankTransferDeposit',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'Menu.BankTransferDeposit'
                },
                path: '/bank-transfer-deposit',
                name: 'bankTransferDeposit',
                component: () => import(/* webpackChunkName: "transaction" */ '../views/Deposit/BankList.vue')
            },
            {
                meta: {
                    title: 'def.bankTransferWithdrawals',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'Menu.BankTransferWithdraw'
                },
                path: '/bank-transfer-withdraw',
                name: 'bankTransferWithdraw',
                component: () => import(/* webpackChunkName: "bankTransfer" */ '../views/Withdraw/BankTransferList.vue')
            },
            {
                meta: {
                    title: 'def.bankTransferAccounts',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'Menu.BankTransferAccounts'
                },
                path: '/bank-transfer-accounts',
                name: 'bankTransferAccounts',
                component: () => import(/* webpackChunkName: "bankTransfer" */ '../views/BankAccount/BankTransferList.vue')
            },
            {
                meta: {
                    title: 'def.bankTransferAccountCreate',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'BankTransferAccount.Create'
                },
                path: '/bank-transfer-account-create',
                name: 'bankTransferAccountCreate',
                component: () => import(/* webpackChunkName: "transaction" */ '../views/BankAccount/BankTransferCreateOrUpdate.vue')
            },
            {
                meta: {
                    title: 'menu.withdrawals',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'Menu.Withdraw'
                },
                path: '/withdraw',
                name: 'withdraw',
                component: () => import(/* webpackChunkName: "transaction" */ '../views/Withdraw/List.vue')
            },
            {
                name: 'reports',
                path: '/report',
                component: () => import(/* webpackChunkName: "report" */ '../views/Report/Index.vue'),
                children: [
                    {
                        meta: {
                            title: 'def.cageReport',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.CageReport'
                        },
                        name: 'cageReportv2',
                        path: 'cage-v2',
                        component: () => import(/* webpackChunkName: "report" */ '../views/Report/Cagev2.vue')
                    },
                    {
                        meta: {
                            title: 'menu.cage',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.CageReport'
                        },
                        name: 'cageReport',
                        path: 'cage',
                        component: () => import(/* webpackChunkName: "report" */ '../views/Report/Cage.vue')
                    },
                    {
                        meta: {
                            title: 'menu.yearlyDeposit',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.YearlyDepositReport'
                        },
                        name: 'yearlyDepositReport',
                        path: 'yearly-deposit',
                        component: () => import(/* webpackChunkName: "report" */ '../views/Report/YearlyDeposit.vue')
                    },
                    {
                        meta: {
                            title: 'menu.yearlyWithdraw',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.YearlyWithdrawReport'
                        },
                        name: 'yearlyWithdrawReport',
                        path: 'yearly-withdraw',
                        component: () => import(/* webpackChunkName: "report" */ '../views/Report/YearlyWithdraw.vue')
                    },
                    {
                        meta: {
                            title: 'menu.investment',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.PlayerReport'
                        },
                        name: 'investorReport',
                        path: 'investor',
                        component: () => import(/* webpackChunkName: "report" */ '../views/Report/Player.vue')
                    },
                    {
                        meta: {
                            title: 'menu.accounts',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.DailyAccountReport'
                        },
                        name: 'dailyAccountReport',
                        path: 'daily-account',
                        component: () => import(/* webpackChunkName: "report" */ '../views/Report/DailyAccount.vue')
                    },
                    {
                        meta: {
                            title: 'def.errorReport',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.FailedTransactionReport'
                        },
                        name: 'failedTransactionReport',
                        path: 'failed-transaction-report',
                        component: () => import(/* webpackChunkName: "report" */ '../views/Report/FailedTransaction.vue')
                    },
                    {
                        meta: {
                            title: 'Raporlar | Havale & Grup Yatırım Raporu',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.BankTransferGroupReport'
                        },
                        name: 'bankTransferGroupReport',
                        path: 'bank-transfer-group-report',
                        component: () => import(/* webpackChunkName: "report" */ '../views/Report/BankTransferGroup.vue')
                    }
                ]
            },
            {
                path: '/definition',
                component: () => import(/* webpackChunkName: "home" */ '../views/Definition/Index.vue'),
                children: [
                    {
                        meta: {
                            title: 'menu.currencies',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.Currency'
                        },
                        path: 'currencies',
                        name: 'currencies',
                        component: () => import(/* webpackChunkName: "onlineUser" */ '../views/Definition/Currency/List.vue')
                    },
                    {
                        meta: {
                            title: 'menu.onlineUsers',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.OnlineUsers'
                        },
                        path: 'online-users',
                        name: 'onlineUsers',
                        component: () => import(/* webpackChunkName: "onlineUser" */ '../views/User/OnlineList.vue')
                    },
                    {
                        meta: {
                            title: "menu.bannedIp",
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.BannedIP'
                        },
                        path: 'banned-list',
                        name: 'bannedList',
                        component: () => import(/* webpackChunkName: "bannedList" */ '../views/User/BannedList.vue')
                    },
                    {
                        meta: {
                            title: 'menu.users',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.User'
                        },
                        path: 'user',
                        name: 'user',
                        component: () => import(/* webpackChunkName: "user" */ '../views/User/List.vue')
                    },
                    {
                        meta: {
                            title: 'menu.settings',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.Setting'
                        },
                        path: 'setting',
                        name: 'setting',
                        component: () => import(/* webpackChunkName: "setting" */ '../views/Setting/Index.vue')
                    },
                    {
                        meta: {
                            title: 'menu.telegramSettings',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.TelegramSetting'
                        },
                        path: 'telegram-setting',
                        name: 'telegramSetting',
                        component: () => import(/* webpackChunkName: "setting" */ '../views/Definition/TelegramSetting/Index.vue')
                    },
                    {
                        meta: {
                            title: 'menu.maldoWithdrawSettings',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.MaldoPanel'
                        },
                        path: 'maldo-withdraw-panel',
                        name: 'maldoWithdrawPanel',
                        component: () => import(/* webpackChunkName: "setting" */ '../views/Definition/MaldoWithdrawPanel/Index.vue'),
                        redirect: 'withdrawals',
                        children: [
                            {
                                meta: {
                                    title: 'menu.withdrawals',
                                    middleware: [auth, access, passwordUpdate],
                                    perm: 'Menu.MaldoPanel'
                                },
                                name: 'maldoWithdrawList',
                                path: 'withdrawals',
                                component: () => import(/* webpackChunkName: "report" */ '../views/Definition/MaldoWithdrawPanel/WithdrawList.vue')
                            },
                        ]
                    },
                    {
                        meta: {
                            title: 'def.userCreateOrUpdate',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'User.Create'
                        },
                        path: 'user-create/:id?',
                        name: 'userCreateOrUpdate',
                        component: () => import(/* webpackChunkName: "user" */ '../views/User/CreateOrUpdate.vue')
                    },
                    {
                        meta: {
                            title: 'menu.paymentMethods',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.PaymentMethod'
                        },
                        path: 'payment-method',
                        name: 'paymentMethod',
                        component: () => import(/* webpackChunkName: "paymentMethod" */ '../views/Bank/List.vue')
                    },
                    {
                        meta: {
                            title: 'menu.paymentMethodCreateOrUpdate', middleware: [auth, access, passwordUpdate],
                            perm: 'PaymentMethod.Create'
                        },
                        path: 'payment-meyhod-create/:id?',
                        name: 'paymentMethodCreateOrUpdate',
                        component: () => import(/* webpackChunkName: "paymentMethod" */ '../views/Bank/CreateOrUpdate.vue')
                    },
                    {
                        meta: {
                            title: 'menu.banks',
                            middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.Bank'
                        },
                        path: 'banklist',
                        name: 'banklist',
                        component: () => import(/* webpackChunkName: "bank" */ '../views/BankList/List.vue')
                    },
                    {
                        meta: {
                            title: 'menu.bankCreateOrUpdate', middleware: [auth, access, passwordUpdate],
                            perm: 'Bank.Create'
                        },
                        path: 'banklist-create/:id?',
                        name: 'bankListCreateOrUpdate',
                        component: () => import(/* webpackChunkName: "banklist" */ '../views/BankList/CreateOrUpdate.vue')
                    },
                    {
                        meta: {
                            title: 'menu.bankAccounts', middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.BankAccount'
                        },
                        path: 'bank-account',
                        name: 'bankAccount',
                        component: () => import(/* webpackChunkName: "bank" */ '../views/BankAccount/List.vue')
                    },
                    {
                        meta: {
                            title: 'menu.bankAccountCreateOrUpdate', middleware: [auth, access, passwordUpdate],
                            perm: 'BankAccount.Create'
                        },
                        path: 'bank-account-create/:id?',
                        name: 'bankAccountCreateOrUpdate',
                        component: () => import(/* webpackChunkName: "bank" */ '../views/BankAccount/CreateOrUpdate.vue')
                    },
                    {
                        meta: {
                            title: 'menu.sites', middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.Site'
                        },
                        path: 'site',
                        name: 'site',
                        component: () => import(/* webpackChunkName: "site" */ '../views/Site/List.vue')
                    },
                    {
                        meta: {
                            title: 'menu.siteCreatedOrUpdate', middleware: [auth, access, passwordUpdate],
                            perm: 'Site.Create'
                        },
                        path: 'site-create/:id?',
                        name: 'siteCreateOrUpdate',
                        component: () => import(/* webpackChunkName: "site" */ '../views/Site/CreateOrUpdate.vue')
                    },
                    {
                        meta: {
                            title: 'menu.blacklist', middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.Blacklist'
                        },
                        path: 'blacklist',
                        name: 'blacklist',
                        component: () => import(/* webpackChunkName: "blacklist" */ '../views/Blacklist/List.vue')
                    },
                    {
                        meta: {
                            title: 'menu.blacklistCreateOrUpdate', middleware: [auth, access, passwordUpdate],
                            perm: 'Blacklist.Create'
                        },
                        path: 'blacklist-create/:id?',
                        name: 'blacklistCreateOrUpdate',
                        component: () => import(/* webpackChunkName: "blacklist" */ '../views/Blacklist/CreateOrUpdate.vue')
                    },
                    {
                        meta: {
                            title: 'menu.currencies', middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.Currency'
                        },
                        path: 'currency',
                        name: 'currency',
                        component: () => import(/* webpackChunkName: "currency" */ '../views/Definition/Currency/List.vue')
                    },
                    {
                        meta: {
                            title: 'menu.currencyCreateOrUpdate', middleware: [auth, access, passwordUpdate],
                            perm: 'Currency.Create'
                        },
                        path: 'currency-create/:id?',
                        name: 'currencyCreateOrUpdate',
                        component: () => import(/* webpackChunkName: "currency" */ '../views/Definition/Currency/CreateOrUpdate.vue')
                    },
                    {
                        meta: {
                            title: 'menu.roles', middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.Role'
                        },
                        path: 'role',
                        name: 'role',
                        component: () => import(/* webpackChunkName: "role" */ '../views/Definition/Role/List.vue')
                    },
                    {
                        meta: {
                            title: 'menu.roleCreateOrUpdate', middleware: [auth, access, passwordUpdate],
                            perm: 'Role.Create'
                        },
                        path: 'role-create/:id?',
                        name: 'roleCreateOrUpdate',
                        component: () => import(/* webpackChunkName: "role" */ '../views/Definition/Role/CreateOrUpdate.vue')
                    },
                    {
                        meta: {
                            title: 'menu.permissions', middleware: [auth, access, passwordUpdate],
                            perm: 'Menu.Permission'
                        },
                        path: 'permission',
                        name: 'permission',
                        component: () => import(/* webpackChunkName: "permission" */ '../views/Definition/Permission/List.vue')
                    }
                ]
            },
            {
                meta: {
                    title: 'menu.apiCreator',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'Menu.ApiCreator'
                },
                path: '/api-creator',
                name: 'apiCreator',
                component: () => import(/* webpackChunkName: "api-creator" */ '../views/ApiCreator.vue')
            },
            {
                meta: {
                    title: 'menu.investors',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'Menu.Investors'
                },
                path: 'investors',
                name: 'investors',
                component: () => import(/* webpackChunkName: "investor" */ '../views/Investor/List.vue')
            },
            {
                meta: {
                    title: 'menu.investorDetail',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'Investor.Detail'
                },
                path: 'investor-detail/:id?',
                name: 'investorDetail',
                component: () => import(/* webpackChunkName: "investor" */ '../views/Investor/Detail.vue')
            },
            {
                meta: {
                    title: 'menu.activityLogs',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'ActivityLog.List'
                },
                path: 'activity-logs',
                name: 'activityLogs',
                component: () => import(/* webpackChunkName: "activityLogs" */ '../views/ActivityLog/List.vue')
            },
            {
                meta: {
                    title: 'menu.loginLogs',
                    middleware: [auth, access, passwordUpdate],
                    perm: 'Menu.Logins'
                },
                path: 'login-logs',
                name: 'loginLogs',
                component: () => import(/* webpackChunkName: "activityLogs" */ '../views/LoginLogs/List.vue')
            },
        ]
    },
    {
        meta: { title: 'def.login' },
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ `../views/${appName === 'rabbitpay' ? 'RabbitLogin.vue' : (appName === 'megapay' ? 'MegaLogin.vue' : 'Login.vue')}`)
    },
    {
        meta: { title: 'def.noAccess' },
        path: '/no-access',
        name: 'noAccess',
        component: () => import(/* webpackChunkName: "login" */ '../views/NoAccess.vue')
    },
    {
        meta: { title: 'def.loggingOut', middleware: [auth] },
        path: '/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "login" */ '../views/Logout.vue')
    },
    {
        meta: { title: 'def.paparaTransfer' },
        path: '/payment/papara/:id',
        name: 'paparaPayManual',
        component: () => import(/* webpackChunkName: "papara" */ '../views/PaparaPayManual.vue')
    },
    {
        meta: { title: 'def.documentation' },
        path: '/v1/documentation',
        name: 'documentation',
        component: () => import(/* webpackChunkName: "documentation" */ `../views/Documentation.vue`)
    },
    {
        meta: { title: 'def.paparaPay' },
        path: '/papara/pay/:id',
        name: 'paparaPay',
        component: () => import(/* webpackChunkName: "paparaPay" */ `../views/Iframe/${appName}/PaparaPay.vue`)
    },
    {
        meta: { title: 'def.tetherPay' },
        path: '/tether/pay/:id',
        name: 'tetherPay',
        component: () => import(/* webpackChunkName: "tetherPay" */ `../views/Iframe/${appName}/TetherPay.vue`)
    },
    {
        meta: { title: 'def.bankTransferPay' },
        path: '/bank-transfer/pay/:id',
        name: 'bankTransferPay',
        component: () => import(/* webpackChunkName: "bankTransferPay" */ `../views/Iframe/${appName}/BankTransferPay.vue`)
    },
    {
        meta: { title: 'def.fastBluPay' },
        path: '/fastblu/pay/:id',
        name: 'fastBluPay',
        component: () => import(/* webpackChunkName: "fastBluPay" */ `../views/Iframe/${appName}/FastBluPay.vue`)
    },
    {
        meta: { title: 'def.withdrawPay' },
        path: '/withdraw/pay/:id',
        name: 'withdrawPay',
        component: () => import(/* webpackChunkName: "withdrawPay" */ `../views/Iframe/${appName}/WithdrawPay.vue`)
    },
    {
        meta: { title: 'def.paparaPay' },
        path: '/papara/iframe',
        name: 'paparaIframe',
        component: () => import(/* webpackChunkName: "paparaIframe" */ `../views/Iframe/${appName}/PaparaIframe.vue`)
    },
    {
        meta: { title: 'def.withdrawPay' },
        path: '/withdraw/iframe',
        name: 'withdrawIframe',
        component: () => import(/* webpackChunkName: "withdrawIframe" */ `../views/Iframe/${appName}/WithdrawIframe.vue`)
    },
    {
        meta: { title: 'def.bankTransferPay' },
        path: '/bank-transfer/iframe',
        name: 'banktransferIframe',
        component: () => import(/* webpackChunkName: "bankTransferIframe" */ `../views/Iframe/${appName}/BankTransferIframe.vue`)
    },
    {
        meta: { title: 'def.tetherPay' },
        path: '/tether/iframe',
        name: 'tetherIframe',
        component: () => import(/* webpackChunkName: "tetherIframe" */ `../views/Iframe/${appName}/TetherIframe.vue`)
    },
    {
        meta: { title: 'def.payfixPay' },
        path: '/payfix/iframe',
        name: 'payfixIframe',
        component: () => import(/* webpackChunkName: "payfixIframe" */ `../views/Iframe/${appName}/PayfixIframe.vue`)
    },
    {
        meta: { title: 'def.cardPay' },
        path: '/card/iframe',
        name: 'cardIframe',
        component: () => import(/* webpackChunkName: "cardIframe" */ `../views/Iframe/${appName}/CreditCardIframe.vue`)
    },
    {
        meta: { title: 'def.paymentSuccess' },
        path: '/payment/success',
        name: 'paymentSuccess',
        component: () => import(/* webpackChunkName: "paymentSuccess" */ '../views/Iframe/PaymentSuccess.vue')
    },
    {
        meta: { title: 'def.paymentError' },
        path: '/payment/error',
        name: 'paymentError',
        component: () => import(/* webpackChunkName: "paymentError" */ '../views/Iframe/PaymentError.vue')
    },
    {
        meta: {title: 'def.fastPapara'},
        path: '/hizli-papara/:id',
        name: 'hizliPapara',
        component: () => import(/* webpackChunkName: "paparaToIbanPay" */ '../views/HizliPapara.vue')
    },
    {
        meta: {title: 'def.paycoPay'},
        path: '/payco/:id',
        name: 'payco',
        component: () => import(/* webpackChunkName: "payco" */ '../views/Payco.vue')
    },
    {
        meta: {title: 'def.payfixPay'},
        path: '/payfix/:id',
        name: 'payfix',
        component: () => import(/* webpackChunkName: "payfix" */ '../views/Payfix.vue')
    },
    {
        meta: {title: 'def.cardPay'},
        path: '/twc/:id',
        name: 'twc',
        component: () => import(/* webpackChunkName: "twc" */ '../views/TWC.vue')
    },
    {
        meta: {title: 'def.cardPay'},
        path: '/cc-payment-check/:id',
        name: 'cc-payment-check',
        component: () => import(/* webpackChunkName: "newpaytr" */ '../views/NewPayTR.vue')
    },
    { path: "/404", name: '404', component: () => import(/* webpackChunkName: "404" */ '../views/404.vue') },
    { path: "*", component: () => import(/* webpackChunkName: "404" */ '../views/404.vue') }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index]
    if (!subsequentMiddleware) return context.next

    return (...parameters) => {
        context.next(...parameters)
        const nextMiddleware = nextFactory(context, middleware, index + 1)
        subsequentMiddleware({ ...context, next: nextMiddleware })
    }
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

        const context = {
            from,
            next,
            router,
            to
        }
        const nextMiddleware = nextFactory(context, middleware, 1)

        return middleware[0]({ ...context, next: nextMiddleware })
    }

    return next()
})

export default router
